import styled from 'styled-components';
import React from 'react';
import Logo from '#/shared/Logo';

const title = 'SAPIENT';

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
  padding: 0 0 0.75rem 4.5rem;
`;

const Header = styled.div`
  width: 90rem;
  height: 1.5rem;
  padding: 0 6.25rem;
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.highlight};
  #logo {
    margin: 0 1.25rem 0 3.75rem;
    width: 26px;
    height: 20px;
  }
`;

const Title = styled.div`
  ${({ theme }: { theme: Theme }): string => theme.header5};
  display: flex;
  align-items: center;
`;

const UnauthenticatedHeader: React.FC = () => (
  <Container>
    <Header>
      <Logo />
      <Title>{title}</Title>
    </Header>
  </Container>
);

export default UnauthenticatedHeader;
