import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { Deal, DealTerm } from '#/types/Deal';
import getDeals from '#/api/getDeals';
import formatApiError from '#/api/utils/formatApiError';

interface Option<Type> {
  id: number;
  value: Type;
  label: string;
  dealTerms?: DealTerm[];
}

interface DealParams {
  stakeholder?:string,
  autobrokerEventId?:number
}

interface DealsHook {
  deals: Deal[];
  dealsError: string;
  fetchDeals: () => void;
  dealOptions: Option<string>[];
  selectedDeal: string;
  setSelectedDeal: (dealLabel: string) => void;
  dealTermOptions: Option<string>[];
}

const useFetchDeals = ({ stakeholder, autobrokerEventId }: DealParams = {}): DealsHook => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [dealsError, setDealsError] = useState<string>('');
  const [selectedDeal, setSelectedDeal] = useState<string>(null);

  const fetchDeals = useCallback(async () => {
    try {
      const d = await getDeals(stakeholder, autobrokerEventId);

      setDeals(d);
    } catch (err) {
      const errorString = formatApiError(err);

      setDealsError(errorString);
    }
  }, [stakeholder, autobrokerEventId, setDeals, setDealsError]);

  useEffect(() => {
    fetchDeals();
  }, [fetchDeals]);

  const dealOptions = useMemo(() => {
    return deals.map((deal) => ({
      id: deal.id,
      value: deal.name,
      label: deal.displayName,
      dealTerms: deal.dealTerms,
    }));
  }, [deals]);

  const dealTermOptions = useMemo(() => {
    const thisSelectedDeal = deals?.find(({ name }) => name === selectedDeal);

    return thisSelectedDeal?.dealTerms?.map((term) => ({
      id: term.id,
      value: term.name,
      label: term.dealTermType,
    }));
  }, [deals, selectedDeal]);

  return {
    deals,
    dealsError,
    fetchDeals,
    dealOptions,
    selectedDeal,
    setSelectedDeal,
    dealTermOptions,
  };
};

export default useFetchDeals;
export { DealsHook, type Option };
