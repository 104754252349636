import React, { useContext, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '#/shared/Logo';
import Avatar from './Avatar';
import ReportingContext from '#/pages/clientReporting/ReportingContext';

const HOME_PATH = '/';
const HEADER_HEIGHT = '4.5rem';

const Header: React.FC = () => {
  const { user } = useContext(ReportingContext);
  const navigateToReportingHome = useCallback(() => {
    window.location.href = HOME_PATH;
  }, []);

  return (
    <HeaderContainer>
      <HeaderContent>
        <LogoButton onClick={navigateToReportingHome}>
          <LogoMark>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Sim />
          </LogoMark>
        </LogoButton>
        {user && (
          <AvatarButton>
            <Avatar
              fullName={user?.fullName()}
              initials={user?.initials()}
              profileImageUrl={user?.profileImageUrl}
            />
          </AvatarButton>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: ${HEADER_HEIGHT};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.0625rem 0px;
  position: fixed;
  z-index: 1000;
`;

const LogoButton = styled.button`
  height: 100%;
  background: none;
  color: ${({ theme }: { theme: Theme }): string => theme.palette.brand.base};
  transition: color 200ms ease 0s;
  &:hover {
    color: ${({ theme }: { theme: Theme }): string => theme.palette.brand.dark};
  }
  cursor: pointer;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  height: 100%;
`;

const LogoMark = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
`;

const LogoWrapper = styled.div`
  #logo {
    height: 2em;
  }
  padding-right: 0.9rem;
  display: flex;
  align-items: center;
`;

const Sim = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.25rem 0.9rem;
  &:before {
    font-weight: 800;
    content: 'sim';
    color: currentcolor;
    font-size: 15.25px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
`;

const AvatarButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 1rem;
  opacity: 1;
  background: none;
  transition: opacity 200ms ease 0s;
  &:hover {
    opacity: 0.75;
  }
  padding: 0 0.5rem;
  cursor: pointer;
`;

export default Header;
export { HEADER_HEIGHT };
