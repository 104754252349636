import React from 'react';
import {
  NarrowWrapper, Element, Name, Value,
} from '#/shared/Card';
import { PRICING_BASIS_OPTIONS } from '#/types/Event';
import Listing from '#/types/Listing';
import { formatCurrency } from '#/shared/formatNumberForDisplay';

interface CardProps {
  listing: Listing;
}

const InfoCard: React.FC<CardProps> = ({ listing }) => {
  const { price, quantity } = listing;
  const {
    amount, basis, log_price_ratio, log_expected_value, expected_value,
  } = price;
  const priceLabel = PRICING_BASIS_OPTIONS.find((option) => option.value === basis)?.label;

  return (
    <NarrowWrapper>
      {log_expected_value && (
        <Element>
          <Name>{`Log Expected Value: ${log_expected_value.toFixed(2)}`}</Name>
        </Element>
      )}
      {expected_value && (
        <Element>
          <Name>{`Expected Value: ${formatCurrency(expected_value)}`}</Name>
        </Element>
      )}
      {log_price_ratio && (
        <Element>
          <Name>{`Log Price Ratio: ${log_price_ratio.toFixed(2)}`}</Name>
        </Element>
      )}
      <Element>
        <Name>{priceLabel}</Name>
        <Value>{`${quantity}x • ${formatCurrency(amount)}`}</Value>
      </Element>
    </NarrowWrapper>
  );
};

export default InfoCard;
