/* eslint-disable import/max-dependencies */
import React from 'react';
import styled from 'styled-components';

import Listing, { PeakpassPackageType } from '#/types/Listing';
import Peakpass from '#images/Peakpass.svg';
import Fallback from '#images/Fallback.svg';
import PriceConstraint from '#images/PriceConstraint.svg';
import Oneticket from '#images/Oneticket.svg';
import Override from '#images/Override.svg';
import PendingOverride from '#images/PendingOverride.svg';
import Visible from '#images/Visible.svg';
import Vip from '#images/Vip.svg';
import Bundle from '#images/Bundle.svg';
import Aisle from '#images/Aisle.svg';
import Prime from '#images/Prime.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Pause from '#images/Pause.svg';
import Consumer from '#images/ConsumerUploader.svg';
import Marketplace from '#images/Marketplace.svg';

interface ListingIconsProps {
  listing: Listing;
  stakeholderLogo?: string;
}

const IngestionIcons: React.FC<ListingIconsProps> = ({ listing, stakeholderLogo }) => {
  const { peakpass_package_type, stakeholder } = listing;

  let PackageTypeImage = null;

  switch (peakpass_package_type) {
    case PeakpassPackageType.VIP:
      PackageTypeImage = Vip;
      break;
    case PeakpassPackageType.BUNDLE:
      PackageTypeImage = Bundle;
      break;
    case PeakpassPackageType.AISLE:
      PackageTypeImage = Aisle;
      break;
    case PeakpassPackageType.PRIME:
      PackageTypeImage = Prime;
      break;
    default:
      break;
  }

  return (
    <>
      {
        stakeholderLogo && (
          <OffsetImage
            alt="stakeholder"
            height="25"
            src={stakeholderLogo ?? Marketplace}
            title={`stakesholder: ${stakeholder}`}
          />
        )
      }
      {
        listing.source_name === 'peakpass' && (
          <OffsetImage
            alt="peakpass"
            height="25"
            src={Peakpass}
            title="Peakpass source"
          />
        )
      }
      {
        listing.source_name === 'oneticket' && (
          <OffsetImage
            alt="oneticket"
            height="25"
            src={Oneticket}
            title="Oneticket source"
          />
        )
      }
      {
        listing.source_name === 'ticketvault' && (
          <OffsetImage
            alt="ticketvault"
            height="25"
            src={Ticketvault}
            title="Ticketvault source"
          />
        )
      }
      {
        listing.source_name === 'consumer' && (
          <OffsetImage
            alt="consumer"
            height="25"
            src={Consumer}
            title="Consumer source"
          />
        )
      }
      {
        PackageTypeImage && (
          <OffsetImage
            alt="package-type"
            height="25"
            src={PackageTypeImage}
            title={`${peakpass_package_type} package type`}
          />
        )
      }
      {
        listing.price_constraint && (
          <OffsetImage
            alt="constraint"
            height="25"
            src={PriceConstraint}
            title="Has Price Constraint"
          />
        )
      }
      {
        listing.override && listing.override.is_active && !listing.override.is_empty && (
          <OffsetImage
            alt="override"
            height="25"
            src={Override}
            title="Has Override"
          />
        )
      }
      {
        listing.override && !listing.override.is_active && (
          <OffsetImage
            alt="pending-override"
            height="25"
            src={PendingOverride}
            title="Pending Override"
          />
        )
      }
      {
        listing.reserved_at && (
          <OffsetImage
            alt="reserved"
            height="25"
            src={Pause}
            title="Reserved"
          />
        )
      }
      {
        listing.sinks.length > 0 && (
          <OffsetImage
            alt="sinks"
            height="25"
            src={Visible}
            title="Visible on sink"
          />
        )
      }
      {
        listing.fallback_pricing_policy && (
          <OffsetImage
            alt="fallback"
            height="25"
            src={Fallback}
            title="Uses fallback pricing"
          />
        )
      }
    </>
  );
};

const OffsetImage = styled.img`
  padding-left: 5px;
  padding-bottom: 3px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  object-fit: cover;
  object-position: center;
`;

export default IngestionIcons;
