enum CurrentState {
  abListingCount = 'abListingCount',
  abLogPriceRatio = 'abLogPriceRatio',
  abNormalizedListingCount = 'abNormalizedListingCount',
  competitorListingCount = 'competitorListingCount',
  competitorLogPriceRatio = 'competitorLogPriceRatio',
  competitorNormalizedListingCount = 'competitorNormalizedListingCount',
  daysToEvent = 'daysToEvent',
  futureDemand = 'futureDemand',
  isUnconstrained = 'isUnconstrained',
  localDemand = 'localDemand',
  localElasticity = 'localElasticity',
  localMeanTicketsPerTransaction = 'localMeanTicketsPerTransaction',
  logHoursToEvent = 'logHoursToEvent',
  modelAbListingsRemaining = 'modelAbListingsRemaining',
  modelLogPriceRatio = 'modelLogPriceRatio',
  modelLogPriceRatioTarget = 'modelLogPriceRatioTarget',
  projectedSelldown = 'projectedSelldown',
  ticketsSoldCount = 'ticketsSoldCount',
  ticketsSoldGtv = 'ticketsSoldGtv',
  ticketsUnsoldCount = 'ticketsUnsoldCount',
  transactionCount = 'transactionCount',
  listingsVisible = 'listingsVisible',
}

const currentStateLabels = {
  [CurrentState.abListingCount]: 'SIM listing count',
  [CurrentState.abLogPriceRatio]: 'SIM log price ratio',
  [CurrentState.abNormalizedListingCount]: 'SIM normalized listing count',
  [CurrentState.competitorListingCount]: 'CP listing count',
  [CurrentState.competitorLogPriceRatio]: 'CP log price ratio',
  [CurrentState.competitorNormalizedListingCount]:
    'CP normalized listing count',
  [CurrentState.daysToEvent]: 'Days to event',
  [CurrentState.isUnconstrained]: 'Is unconstrained',
  [CurrentState.futureDemand]: 'Future demand',
  [CurrentState.localDemand]: 'Local demand',
  [CurrentState.localElasticity]: 'Local elasticity',
  [CurrentState.localMeanTicketsPerTransaction]:
    'Local mean tickets per transaction',
  [CurrentState.logHoursToEvent]: 'Log hours to event',
  [CurrentState.modelAbListingsRemaining]: 'Model SIM listings remaining',
  [CurrentState.modelLogPriceRatio]: 'Model log price ratio',
  [CurrentState.modelLogPriceRatioTarget]: 'Model log price ratio target',
  [CurrentState.projectedSelldown]: 'Projected selldown',
  [CurrentState.ticketsSoldCount]: 'Tickets sold count',
  [CurrentState.ticketsSoldGtv]: 'Tickets sold GTV',
  [CurrentState.ticketsUnsoldCount]: 'Tickets unsold count',
  [CurrentState.transactionCount]: 'Transaction count',
  [CurrentState.listingsVisible]: 'Visible Listings',
};

export { CurrentState };
export default currentStateLabels;
