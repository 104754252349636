import { max } from 'd3-array';
import { uniqBy } from 'lodash';
import { LOG_SCALE_TICKS } from '#/shared/graphique';
import { PriceDistributionGraphData, PriceDistributionListings } from '#/types/Preview';
import { FormattedPricingChartDatum } from '../../types';
import { calcPrice } from '../../utils/dataFormatting';
import { PRICE_DISTRO_VIEW_OPTIONS } from '../hooks/useViewSelection';
import { PricingBasis } from '#/types/Event';
import { GraphPlot } from '#/types/Snapshot';

const AB = {
  id: 'autobroker',
  label: 'SIM',
};
const CP = {
  id: 'competitor',
  label: 'Competitor',
};
const MODEL = {
  id: 'model',
  label: 'Model',
};

const groups = [AB.label, CP.label, MODEL.label];

const isTarget = (k: string): boolean => k.toLowerCase().includes('target');
const isListing = (k: string): boolean => k.toLowerCase().includes('listing');

const labelGroup = (v: string): string | undefined => {
  if (v && v.includes(AB.id))
    return AB.label;
  if (v && v.includes(CP.id))
    return CP.label;
  if (v && v.includes(MODEL.id))
    return MODEL.label;
  return undefined;
};

// we shouldn't get duplicate listings, but we can make sure we don't
const dedupListings = (listingsData: GraphPlot[]): GraphPlot[] => (
  uniqBy(listingsData, 'id')
);

const getMeasureLabel = (d: GraphPlot): string => (
  PRICE_DISTRO_VIEW_OPTIONS.find((o) => o.key === d.marketplace)?.label
    ?? `${d.marketplace} listings`
);

const formatListings = (
  data: PriceDistributionListings,
): FormattedPricingChartDatum[] => {
  const keys = Object.keys(data) as (keyof PriceDistributionListings)[];

  const listingsData: FormattedPricingChartDatum[] = keys.flatMap((k) => (
    dedupListings(data[k]).map((d) => {
      return ({
        group: labelGroup(k),
        measure: getMeasureLabel(d),
        key: `${k}-${d.id}`,
        allInPrice: d.allInPrice,
        displayPrice: d.displayPrice,
        inventorySourceType: d.inventorySourceType,
        sellerId: d.sellerId,
        quantity: d.quantity,
        logExpectedValue: d.x,
        logPriceRatio: d.y,
        marketplace: d.marketplace,
      } as FormattedPricingChartDatum);
    })
  ));

  return listingsData;
};

const formatDistributionData = (
  data: PriceDistributionGraphData,
): FormattedPricingChartDatum[] => {
  const keys = Object.keys(data) as (keyof PriceDistributionGraphData)[];

  const formatted: FormattedPricingChartDatum[] = keys.flatMap((k) => (
    data[k].map((d, i) => {
      const sharedData: FormattedPricingChartDatum = {
        probability: d.probability,
        group: labelGroup(k),
        measure: k,
        logPriceRatio: max(d.graphPlot, (g) => g.y),
        price: max(d.graphPlot, (g) => calcPrice(g.x, g.y)),
        key: `${k}-${i}`,
        priceBand: d?.graphPlot?.map((gp) => {
          const logPriceRatio = isTarget(k) ? gp.y : undefined;
          const price = isTarget(k) ? calcPrice(gp.x, gp.y) : undefined;
          const logPriceRatioMin = isTarget(k) ? undefined : gp.y0;
          const priceMin = isTarget(k) ? undefined : calcPrice(gp.x, gp.y0);
          const logPriceRatioMax = isTarget(k) ? undefined : gp.y;
          const priceMax = isTarget(k) ? undefined : calcPrice(gp.x, gp.y);

          return ({
            logExpectedValue: gp.x,
            logPriceRatio,
            price,
            logPriceRatioMin,
            priceMin,
            logPriceRatioMax,
            priceMax,
          });
        }),
      };

      return sharedData;
    })
  ));

  return formatted;
};

const formatMoney = (value: number): string | undefined => {
  if (typeof value !== 'undefined') {
    return (
      value >= 1000 ? `$${(value / 1000).toLocaleString()}K`
        : `$${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
    );
  }
  return undefined;
};

const formatDollarYTicks = (value: number, range: number): string => {
  if (range < 100)
    return formatMoney(value);
  if (LOG_SCALE_TICKS.includes(Math.abs(value)))
    return formatMoney(value);
  return '';
};

const formatTooltipX = (v: number): string => `$${v.toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})}`;

const Y_BUFFER = 1.1;
const Y_PRICE_BUFFER = 1.05;

const priceAccessor = (d: FormattedPricingChartDatum, basis: PricingBasis): number => (
  basis === PricingBasis.DISPLAY_PRICE ? d.displayPrice : d.allInPrice
);

const getMaxDisplayY = (
  data: FormattedPricingChartDatum[], isPrice: boolean, basis: PricingBasis, hasBuffer: boolean,
): number => {
  const bufferValue = isPrice ? Y_PRICE_BUFFER : Y_BUFFER;
  const maxY = max(data, (d) => isPrice ? (priceAccessor(d, basis) ?? d.price) : d.logPriceRatio)
    * (hasBuffer ? bufferValue : 1);

  return maxY;
};

const formatPrice = ({ value }: { value: number }): string => formatMoney(value);
const formatDecimal = (value: number): string => (
  value?.toLocaleString(undefined, { maximumFractionDigits: 2 })
);

export {
  formatListings,
  formatDistributionData,
  isTarget,
  isListing,
  formatDollarYTicks,
  formatPrice,
  formatDecimal,
  formatTooltipX,
  getMaxDisplayY,
  priceAccessor,
  groups,
};
