import React from 'react';
import Listing from '#/types/Listing';
import IngestionCard from './IngestionCard';
import PriceConstraintCard from './PriceConstraintCard';
import InputListingOverrideCard from '#/shared/InputListingOverrideCard';

interface ListingFunnelCardsProps {
  listing: Listing;
}

const ListingFunnelCards: React.FC<ListingFunnelCardsProps> = ({
  listing,
}) => {
  const {
    ingestion_listing_id, price_constraint, override,
  } = listing;

  return (
    <>
      {ingestion_listing_id && <IngestionCard listing={listing} />}
      {price_constraint && <PriceConstraintCard listing={listing} />}
      {override && (!override.is_empty || !override.is_active) && (
        <InputListingOverrideCard override={override} />
      )}
    </>
  );
};

export default ListingFunnelCards;
