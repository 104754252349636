import React from 'react';
import IngestedCard from './IngestedCard';
import InventoryCard from './InventoryCard';
import IngestedCostCard from './IngestedCostCard';
import SalesCard from './SalesCard';
import GtvCard from './GtvCard';
import SaleCostCard from './SaleCostCard';
import ScheduledRunCard, { NOT_RUNNING_REASONS } from './ScheduledRunCard';
import ScheduleStatusCard from './ScheduleStatusCard';
import SinkCard from './SinkCard';
import PartitionCard from './PartitionCard';
import { CardsWrapper } from '#/shared/Card';
import Event, { ValueField } from '#/types/Event';
import AvgCard from './AvgCard';
import PortfolioValueCard from './PortfolioValueCard';
import PortfolioRiskCard from './PortfolioRiskCard';
import PendingModelCard from './PendingModelsCard';

interface SummaryCardsProps {
  event: Event;
  valueField: ValueField;
}

const SummaryCards: React.FC<SummaryCardsProps> = ({ event, valueField }) => {
  return (
    <CardsWrapper>
      <IngestedCard event={event} />
      <InventoryCard event={event} />
      <SalesCard event={event} />
      <IngestedCostCard event={event} valueField={valueField} />
      <GtvCard event={event} />
      <SaleCostCard event={event} valueField={valueField} />
      {event.summary.visible.length > 0 && (
        event.summary.visible.map((summary) => (
          <SinkCard
            key={summary.sinkName}
            sinkEvents={event.sinkEvents.filter((se) => se.sinkName === summary.sinkName)}
            summary={summary}
          />
        ))
      )}
      {(event.summary.saleCt > 0 || event.summary.listingCt > 0) && (
        <AvgCard event={event} />
      )}
      {event.schedule !== null && (
        <>
          {
            NOT_RUNNING_REASONS.includes(event.schedule.reason)
              ? <PendingModelCard event={event} />
              : <ScheduledRunCard schedule={event.schedule} />
          }
          <ScheduleStatusCard schedule={event.schedule} />
        </>
      )}
      {event.summary.portfolioValue > 0 && (
        <PortfolioValueCard event={event} />
      )}
      {event.summary.portfolioValue > 0 && event.selldown && event.selldown.now > 0 && (
        <PortfolioRiskCard event={event} />
      )}
      {event.partitions.length > 0 && (
        <PartitionCard
          autobrokerEventId={event.id}
          partitions={event.partitions}
        />
      )}
    </CardsWrapper>
  );
};

export default SummaryCards;
