import React, { useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';

import TableWrapper, {
  Cell,
  NoWrapCell,
  SmallCell,
  Header,
  ClickableRowWrapper,
  RowWrapper,
} from '#/shared/TableComponents';
import Loader from '#/shared/Loader';
import Error from '#/shared/Error';
import palette from '#/theme/palettes/main';
import { UseListingsHook } from './useFetchListings';
import Listing from '#/types/Listing';
import { formatGtv } from '#/shared/formatNumberForDisplay';
import ListingIcons from './ListingIcons';
import PageButton from '#/shared/Button';
import { Button } from '#/shared/Detail';
import Edit from '#images/Edit.svg';
import { InputListing } from '#/shared/ListingOverrideModal';
import DownArrow from '#images/DownArrow.svg';
import UpArrow from '#images/UpArrow.svg';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import { PriceGranularity } from '#/types/Event';
import { StakeholderLogos } from '#/pages/useFetchStakeholders';

const ERROR_COPY = 'Something went wrong retrieving listings!';
const PREVIOUS_PAGE = 'Previous Page';
const NEXT_PAGE = 'Next Page';

interface Column {
  label: string;
  colSpan: number;
  sortValue?: string;
}

interface ListingsTableProps {
  selectedIds: number[];
  setSelected: React.Dispatch<React.SetStateAction<Listing[]>>;
  listingsHook: UseListingsHook;
  stakeholderLogos: StakeholderLogos;
  setOverrideListingModal: (listings: InputListing[]) => void;
  canEdit: boolean;
}
const ListingsTable: React.FC<ListingsTableProps> = ({
  selectedIds,
  setSelected,
  listingsHook,
  stakeholderLogos,
  setOverrideListingModal,
  canEdit,
}) => {
  const {
    listings,
    perPage,
    filters,
    setFilters,
    retry,
    pages,
    prevPage,
    nextPage,
    showResults,
    showError,
    showLoader,
  } = listingsHook;
  const { activeEvent } = useContext(ActiveEventContext);
  const hasListingPrices = activeEvent.sourceEvents.some(
    (s) => s.candidatePriceGranularities?.includes(PriceGranularity.LISTING),
  );
  const hasPriceLevelPrices = activeEvent.sourceEvents.some(
    (s) => s.candidatePriceGranularities?.includes(PriceGranularity.PRICE_LEVEL),
  );
  let sortValue: string;

  if (hasListingPrices && !hasPriceLevelPrices)
    sortValue = 'price';
  else if (!hasListingPrices && hasPriceLevelPrices)
    sortValue = 'price_level_price';

  const columns: Column[] = useMemo(() => [
    { label: 'Source ID', colSpan: 1, sortValue: 'source_inventory_id' },
    { label: 'Section', colSpan: 1, sortValue: 'section' },
    { label: 'Row', colSpan: 1, sortValue: 'row' },
    { label: 'Seats', colSpan: 1 },
    { label: 'Qty', colSpan: 1, sortValue: 'quantity' },
    { label: 'Price', colSpan: 1, sortValue },
    { label: 'Cost', colSpan: 1, sortValue: 'cost_per_ticket' },
    { label: 'FV', colSpan: 1, sortValue: 'face_value_per_ticket' },
    { label: 'Constraint', colSpan: 1, sortValue: 'constraint' },
    { label: 'Stakeholder', colSpan: 1, sortValue: 'stakeholder' },
    { label: 'LPR', colSpan: 1, sortValue: 'log_price_ratio' },
    { label: 'EV', colSpan: 1, sortValue: 'log_expected_value' },
    { label: 'Properties', colSpan: 1 },
    { label: 'Edit', colSpan: 1 },
  ], [sortValue]);

  const sourcePriceGranularities = useMemo(() => Object.fromEntries(
    activeEvent
      .sourceEvents
      .map((s) => [s.sourceName, s?.candidatePriceGranularities]),
  ), [activeEvent]);

  const onClick = useCallback((event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.title;
    const selected = listings.find((listing) => listing.source_inventory_id === id);

    if (selected) {
      // support for determining which rows to add as selected, supporting conventional
      // multi-row-select behavior.
      if (event.ctrlKey || event.metaKey) {
        const alreadySelected = selectedIds.includes(selected.id);

        if (alreadySelected)
          setSelected((s) => s.filter((listing) => listing.id !== selected.id));
        else
          setSelected((s) => [...s, selected]);
      } else if (event.shiftKey) {
        const lastSelected = listings.find(
          (listing) => listing.id === selectedIds[selectedIds.length - 1],
        ).source_inventory_id;

        if (lastSelected) {
          const lastIdx = listings.findIndex(
            (listing) => listing.source_inventory_id === lastSelected,
          );
          const curIdx = listings.findIndex((listing) => listing.source_inventory_id === id);
          const start = lastIdx < curIdx ? lastIdx : curIdx;
          const end = lastIdx < curIdx ? curIdx : lastIdx;

          const newSelected = listings
            .filter((listing: Listing, idx: number): boolean => {
              return (
                idx >= start
                && idx <= end
                && !selectedIds.includes(listing.id)
              );
            });

          if (newSelected.length > 0)
            setSelected((s) => [...s, ...newSelected]);
        }
      } else {
        setSelected([selected]);
      }
    }
  }, [setSelected, listings, selectedIds]);
  const filteredColumns = useMemo(() => (
    columns.filter((column) => column.label === 'Edit' ? canEdit : true)
  ), [canEdit, columns]);

  const switchOrder = useCallback(() => {
    const sortAsc = 1 - (filters.sortAsc ?? 0);

    setFilters({ ...filters, sortAsc });
  }, [setFilters, filters]);

  const setSortByColumn = useCallback((column: Column) => {
    if (column.sortValue)
      setFilters({ ...filters, sortBy: column.sortValue, sortAsc: 1 });
  }, [setFilters, filters]);

  const headers = useMemo(() => {
    return filteredColumns.map((column) => {
      if (column.sortValue && column.sortValue === filters.sortBy) {
        return (
          <NoWrapCell key={`sort_cell_${column.sortValue}`} onClick={switchOrder} style={{ cursor: 'pointer' }}>
            <OffsetImage
              alt="sort"
              height="13"
              src={filters.sortAsc ? UpArrow : DownArrow}
              title={filters.sortAsc ? 'Ascending' : 'Descending'}
            />
            {column.label}
          </NoWrapCell>
        );
      }
      return (
        <Cell
          colSpan={column.colSpan}
          key={column.label}
          // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
          onClick={(): void => (setSortByColumn(column))}
          style={{ cursor: column.sortValue ? 'pointer' : null }}
        >
          {column.label}
        </Cell>
      );
    });
  }, [filteredColumns, filters.sortAsc, filters.sortBy, setSortByColumn, switchOrder]);

  return (
    <>
      <TableWrapper>
        <thead>
          <Header>
            {headers}
          </Header>
        </thead>
        {showResults && (
          <tbody>
            {pages.length > 0 && (
              <RowWrapper key="load_previous_rows">
                <td colSpan={filteredColumns.map((column) => column.colSpan)
                  .reduce((a, b) => a + b)}
                >
                  <ButtonWrapper>
                    <PageButton onClick={prevPage}>{PREVIOUS_PAGE}</PageButton>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
            {listings.map((listing) => (
              <ListingsTableRow
                canEdit={canEdit}
                isSelected={selectedIds.includes(listing.id)}
                key={listing.id}
                listing={listing}
                onClick={onClick}
                priceGranularities={sourcePriceGranularities[listing.source_name]}
                setOverrideListingModal={setOverrideListingModal}
                stakeholderLogos={stakeholderLogos}
              />
            ))}
            {listings.length === perPage && (
              <RowWrapper key="load_next_rows">
                <td colSpan={filteredColumns.map((column) => column.colSpan)
                  .reduce((a, b) => a + b)}
                >
                  <ButtonWrapper>
                    <PageButton onClick={nextPage}>{NEXT_PAGE}</PageButton>
                  </ButtonWrapper>
                </td>
              </RowWrapper>
            )}
          </tbody>
        )}
      </TableWrapper>
      {showError && (
        <CenterContainer>
          <Error copy={ERROR_COPY} retry={retry} />
        </CenterContainer>
      )}
      {showLoader && (
        <CenterContainer>
          <Loader hexColor={palette.brand.base} />
        </CenterContainer>
      )}
    </>
  );
};

interface ListingsTableRowProps {
  listing: Listing;
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  setOverrideListingModal: (listings: InputListing[]) => void;
  canEdit: boolean;
  priceGranularities: PriceGranularity[];
  stakeholderLogos: StakeholderLogos;
}

const ListingsTableRow: React.FC<ListingsTableRowProps> = ({
  listing,
  isSelected,
  onClick,
  setOverrideListingModal,
  canEdit,
  priceGranularities,
  stakeholderLogos,
}) => {
  const {
    id,
    source_name,
    source_external_event_id,
    source_inventory_id,
    stakeholder,
    deal,
    section,
    row,
    quantity,
    price,
    price_level_price,
    ingestion_cost_per_ticket,
    ingestion_face_value_per_ticket,
    start_seat,
    end_seat,
    deal_term_name,
    price_constraint,
  } = listing;

  const backgroundColor = isSelected ? palette.brand.light24 : null;
  let renderPrice = '';

  if (priceGranularities?.includes(PriceGranularity.LISTING))
    renderPrice = formatGtv(price.amount);
  else if (price_level_price && priceGranularities?.includes(PriceGranularity.PRICE_LEVEL))
    renderPrice = formatGtv(price_level_price.amount);

  const constraint = price_constraint
    ? [
      price_constraint.price_floor ? `${formatGtv(price_constraint.price_floor)} floor` : null,
      price_constraint.price_ceiling ? `${formatGtv(price_constraint.price_ceiling)} ceiling` : null,
    ]
      .filter((c) => c !== null)
      .join(', ') + `\n${price_constraint.price_constraint_type.split('_').join(' ')}`
    : '';

  const stakeholderLogo = stakeholderLogos[stakeholder];

  const seatRange = `${start_seat} - ${end_seat}`;

  const openOverrideModal = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    // stop the row onClick from being called
    event.stopPropagation();
    setOverrideListingModal([listing]);
  }, [setOverrideListingModal, listing]);

  return (
    <ClickableRowWrapper color={backgroundColor} key={`${id}`} onClick={onClick} title={source_inventory_id}>
      <Cell key={`id_${id}`} style={{ whiteSpace: 'pre', maxWidth: '7rem' }}>
        {`${source_name} event ${source_external_event_id}\n${source_inventory_id}`}
      </Cell>
      <SmallCell key={`section_${id}`} title={section}>{section}</SmallCell>
      <SmallCell key={`row_${id}`} title={row}>{row}</SmallCell>
      <SmallCell key={`seats_${id}`} title={seatRange}>{seatRange}</SmallCell>
      <Cell key={`quantity_${id}`}>{quantity}</Cell>
      <Cell key={`price_${id}`}>{ renderPrice }</Cell>
      <Cell key={`cost_${id}`}>{ingestion_cost_per_ticket ? formatGtv(ingestion_cost_per_ticket) : ''}</Cell>
      <Cell key={`fv_${id}`}>{ingestion_face_value_per_ticket ? formatGtv(ingestion_face_value_per_ticket) : ''}</Cell>
      <Cell key={`constraint_${id}`} style={{ whiteSpace: 'pre' }} title={constraint}>
        {constraint}
      </Cell>
      <Cell key={`stakeholder_${id}`} style={{ whiteSpace: 'pre', maxWidth: '8rem' }}>
        {stakeholder + (deal ? `\n${deal}` : '') + (deal_term_name ? `\n${deal_term_name}` : '')}
      </Cell>
      <Cell key={`lpr_${id}`}>{price.log_price_ratio?.toFixed(2) || ''}</Cell>
      <Cell key={`lev_${id}`}>{price.expected_value?.toFixed(2) || ''}</Cell>
      <NoWrapCell key={`properties_${id}`}>
        <ListingIcons listing={listing} stakeholderLogo={stakeholderLogo} />
      </NoWrapCell>
      { canEdit && (
        <SmallCell>
          <Button onClick={openOverrideModal} title="edit listing">
            <EditImg />
          </Button>
        </SmallCell>
      )}
    </ClickableRowWrapper>
  );
};

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EditImg = styled.img`
  content: url(${Edit});
  height: 20px;
`;

const ButtonWrapper = styled.div`
  text-align:center;
  width:95%;
`;

const OffsetImage = styled.img`
  padding-right: 2px;
`;

export default ListingsTable;
