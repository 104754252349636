import { DateTime } from 'luxon';
import { PriceConstraint } from '#/types/PriceConstraints';
import { APIInputListingOverride, InputListingOverride } from './Ingestion';
import { PricingBasis } from './Event';

enum PeakpassPackageType {
  AISLE = 'Aisle',
  PRIME = 'Prime listing',
  BUNDLE = 'Bundle',
  VIP = 'VIP',
}

interface ListingSeat {
  id: string;
  ordinal: string;
  is_obstructed_view: boolean;
  is_wheelchair_accessible: boolean;
  is_limited_mobility_accessible: boolean;
  available: boolean;
}

interface ListingPrice {
  amount: number;
  basis: PricingBasis;
  log_price_ratio?: number;
  log_expected_value?: number;
  expected_value?: number;
}

interface APIListing extends Omit<Listing, 'expected_value'|'created_at'|'updated_at'|'expired_at'|'override'> {
  override: APIInputListingOverride | null;
  created_at: string;
  updated_at: string;
  expired_at: string | null;
}

interface Listing {
  id: number;
  autobroker_event_id: number;
  seatgeek_event_id: number;
  sinks: string[];
  source_inventory_id: string;
  source_external_event_id: string;
  source_price_level_id: string | null;
  source_price_level_name: string | null;
  source_name: string;
  stakeholder: string | null;
  priority: number | null;
  deal: string | null;
  deal_term_name: string | null;
  ingestion_listing_id: number | null;
  ingestion_cost_per_ticket: number | null;
  ingestion_face_value_per_ticket: number | null;
  section: string;
  row: string | null;
  mapkey: string | null;
  price_constraint: PriceConstraint | null;
  price: ListingPrice
  price_level_price: ListingPrice | null;
  quantity: number;
  splits: number[];
  seats: ListingSeat[];
  start_seat: number | null;
  end_seat: number | null;
  override: InputListingOverride | null;
  peakpass_package_type: PeakpassPackageType | null;
  fallback_pricing_policy: string | null;
  reserved_at: DateTime | null;
  created_at: DateTime;
  updated_at: DateTime;
}

type ListingFormat = 'csv' | 'json';

interface ListingFilters {
  autobrokerEventId?: number;
  seatgeekEventId?: number;
  fromEventDate?: string;
  toEventDate?: string;
  stakeholder?: string;
  deal?: string;
  dealTerm?: string;
  fromId?: number;
  perPage?: number;
  format?: ListingFormat;
  hasHeader?: boolean;
  section?: string;
  row?: string;
  sourceName?: string;
  isVisible?: boolean;
  pricingPackageType?: string;
  sortBy?: string;
  sortAsc?: number;
  timeZone?: string;
}

export default Listing;
export {
  APIListing,
  ListingSeat,
  ListingPrice,
  ListingFilters,
  PeakpassPackageType,
};
