import React, {
  useState, useCallback, useEffect, useContext, useMemo,
} from 'react';

import { ListingFilters, PeakpassPackageType } from '#/types/Listing';
import useFetchStakeholders, { type Option as StakeholderOption } from '#/pages/useFetchStakeholders';
import {
  FilterSelect, Form, getOption, SmallInput,
} from '#/shared/Filters';
import TableWrapper, { Cell, RowWrapper, SmallHeader } from '#/shared/TableComponents';
import Button from '#/shared/Button';
import { ActiveEventContext } from '../../contexts/ActiveEvent';
import useFetchDeals, { type Option as DealOption } from '#/pages/useFetchDeals';
import type { GenericOption, GenericOptionWithId } from '#/types/GenericOption';

const SEARCH = 'Search';
const COLUMNS = [
  'Section',
  'Row',
  'Stakeholder',
  'Deal',
  'Deal Term',
  'Source',
  'Is Visible',
  'Package Type',
  'Per Page',
  '',
];
const VISIBILITY_LABELS = [
  {
    id: 0,
    value: true,
    label: 'Yes',
  },
  {
    id: 1,
    value: false,
    label: 'No',
  },
];
const PACKAGE_TYPE_LABELS = Object.values(PeakpassPackageType).map((p) => ({
  id: p,
  value: p,
  label: p,
}));

interface FilterProps {
  filters: ListingFilters;
  setFilters: (filters: ListingFilters) => void;
}

const Filters: React.FC<FilterProps> = ({
  filters,
  setFilters,
}) => {
  const [input, setInput] = useState<ListingFilters>(filters);
  const { activeEvent } = useContext(ActiveEventContext);
  const autobrokerEventId = useMemo(() => activeEvent?.id, [activeEvent]);
  const {
    dealOptions, selectedDeal, setSelectedDeal, dealTermOptions,
  } = useFetchDeals({ stakeholder: input.stakeholder, autobrokerEventId: autobrokerEventId });
  const { stakeholderLabels } = useFetchStakeholders(autobrokerEventId);
  const sourceOptions = useMemo(() => activeEvent?.sourceEvents?.map(
    (s) => ({ label: s.sourceName, value: s.sourceName }),
  ) || [], [activeEvent]);

  const setSection = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const section = event?.target?.value || null;

    setInput((i) => ({ ...i, section }));
  }, [setInput]);
  const setRow = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const row = event?.target?.value || null;

    setInput((i) => ({ ...i, row }));
  }, [setInput]);

  const setStakeholder = useCallback((event: StakeholderOption<string>) => {
    const stakeholder = event?.value || null;

    setSelectedDeal(null);
    setInput((i) => ({
      ...i, stakeholder, deal: undefined, dealTerm: undefined,
    }));
  }, [setSelectedDeal, setInput]);

  const setSourceName = useCallback((event: GenericOption<string, string>) => {
    setInput({ ...input, sourceName: event?.value });
  },
  [input]);

  const setIsVisible = useCallback((event: GenericOptionWithId<string, boolean>) => {
    const isVisible = event?.value;

    setInput((i) => ({ ...i, isVisible }));
  }, [setInput]);

  const setPackageType = useCallback(
    (event: GenericOptionWithId<PeakpassPackageType, PeakpassPackageType, PeakpassPackageType>) => {
      const pricingPackageType = event?.value;

      setInput((i) => ({ ...i, pricingPackageType }));
    }, [setInput],
  );

  const setDeal = useCallback((event: DealOption<string>) => {
    const deal = event?.value || null;

    setSelectedDeal(deal);
    setInput((i) => ({ ...i, deal, dealTerm: undefined }));
  }, [setSelectedDeal]);

  const setDealTerm = useCallback((event: DealOption<string>) => {
    const dealTerm = event?.value || null;

    setInput((i) => ({ ...i, dealTerm }));
  }, [setInput]);

  const setPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const perPage = Number(event?.target?.value) || null;

    setInput((i) => ({ ...i, perPage }));
  }, [setInput]);

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    setFilters({ ...input, sortBy: filters.sortBy, sortAsc: filters.sortAsc });
  }, [filters.sortAsc, filters.sortBy, input, setFilters]);

  useEffect(() => {
    if (filters.autobrokerEventId)
      setInput(filters);
  }, [filters, filters.autobrokerEventId]);

  const hideDealFilter = useMemo(() => {
    return !input.stakeholder || dealOptions?.length === 0;
  }, [input.stakeholder, dealOptions]);

  const hideDealTermFilter = useMemo(() => !selectedDeal, [selectedDeal]);

  const hideColumn = useCallback((column: string) => {
    switch (column) {
      case 'Deal':
        return hideDealFilter;
      case 'Deal Term':
        return hideDealTermFilter;
      default:
        return false;
    }
  }, [hideDealFilter, hideDealTermFilter]);

  return (
    <Form onSubmit={handleSubmit}>
      <TableWrapper>
        <thead>
          <SmallHeader>
            {COLUMNS.map((column) => (
              <Cell hidden={hideColumn(column)} key={column}>{column}</Cell>
            ))}
          </SmallHeader>
        </thead>
        <tbody>
          <RowWrapper>
            <Cell>
              <SmallInput
                defaultValue={filters.section}
                onChange={setSection}
                type='text'
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.row}
                onChange={setRow}
                type='text'
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.stakeholder, stakeholderLabels)}
                onChange={setStakeholder}
                options={stakeholderLabels}
                width="9rem"
              />
            </Cell>
            {!hideDealFilter
              && (
                <Cell>
                  <FilterSelect
                    defaultValue={getOption(filters.deal, dealOptions)}
                    onChange={setDeal}
                    options={dealOptions}
                    width="9rem"
                  />
                </Cell>
              )}

            {!hideDealTermFilter
                && (
                  <Cell>
                    <FilterSelect
                      defaultValue={getOption(filters.dealTerm, dealTermOptions)}
                      onChange={setDealTerm}
                      options={dealTermOptions}
                      width="9rem"
                    />
                  </Cell>
                )}
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.sourceName, sourceOptions)}
                onChange={setSourceName}
                options={sourceOptions}
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.isVisible, VISIBILITY_LABELS)}
                onChange={setIsVisible}
                options={VISIBILITY_LABELS}
                width="7rem"
              />
            </Cell>
            <Cell>
              <FilterSelect
                defaultValue={getOption(filters.pricingPackageType, PACKAGE_TYPE_LABELS)}
                onChange={setPackageType}
                options={PACKAGE_TYPE_LABELS}
                width="9rem"
              />
            </Cell>
            <Cell>
              <SmallInput
                defaultValue={filters.perPage}
                onChange={setPerPage}
                type='number'
              />
            </Cell>
            <Cell>
              <Button type='submit'>{SEARCH}</Button>
            </Cell>
          </RowWrapper>
        </tbody>
      </TableWrapper>
    </Form>
  );
};

export default Filters;
