import React, { useState, useEffect } from 'react';
import {
  ErrorStatusType, EventError, EventErrorType, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';

interface InventoryMissingErrorChecklistProps {
  error: EventError;
  allErrors: string[];
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.RESOLVED,
    notes: 'SIM Inventory Showing on SeatGeek',
  },
  {
    id: 1,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'SIM Inventory is Missing on SeatGeek',
  },
  {
    id: 2,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Check if SeatGeek Event ID correct. Ensure Sapient Listings Match Active Event ID in Rufus',
    parent_id: 1,
  },
  {
    id: 3,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'ID is NOT Correct - Listings Updated to Match Active Event ID in Rufus',
    parent_id: 2,
  },
  {
    id: 4,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: "Check if OneTicket Event ID is correct. Ensure Ingestions Match DTI's Portal",
    parent_id: 1,
  },
  {
    id: 5,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'ID is NOT Correct - Listing Updated to Match OneTicket Event ID',
    parent_id: 4,
  },
];

const InventoryMissingErrorChecklist: React.FC<InventoryMissingErrorChecklistProps> = (
  { error, allErrors, setErrorStatus },
) => {
  const [checklistToRefer, setChecklistToRefer] = useState<string>(null);
  const [config, setConfig] = useState<ChecklistConfigItem[]>(CONFIG);

  useEffect(() => {
    if (allErrors.includes(EventErrorType.SEATGEEK_MARKET_IS_DISABLED_ERROR))
      setChecklistToRefer('SeatGeek Market is Disabled');
    else if (allErrors.includes(EventErrorType.SELLERDIRECT_EVENT_MATCHING_ERROR))
      setChecklistToRefer('Sellerdirect Event Matching');
    else if (allErrors.includes(EventErrorType.EVENT_NOT_ACTIVE_ON_SEATGEEK_ERROR))
      setChecklistToRefer('Event Not Active');
    else if (allErrors.includes(EventErrorType.MODIFIED_MAP_ERROR))
      setChecklistToRefer('Modified Map');
  }, [allErrors, setChecklistToRefer]);

  useEffect(() => {
    if (checklistToRefer) {
      setConfig([...CONFIG, {
        id: 6,
        level: 2,
        status: ErrorStatusType.INPROGRESS,
        notes: `Refer to ${checklistToRefer} Error Checklist`,
        parent_id: 1,
      }]);
    }
  }, [checklistToRefer]);

  return (
    <Checklist config={config} error={error} setErrorStatus={setErrorStatus} />
  );
};

export default InventoryMissingErrorChecklist;
