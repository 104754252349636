import { DateTime } from 'luxon';
import type {
  Rollup,
  RollupPreview,
  SalesSummaryMeasurement,
  CumulativeMmt,
  TrendMetric,
  PreviewTrendMetric,
} from '#/types/ReportingRollup';
import { EventTimeSeriesSalesSummary } from '#/pages/EventCollectionPage/utils/getSalesTrendsByEvent';

const SALES_METRICS: Record<TrendMetric, CumulativeMmt> = {
  ticketsSold: 'cumulativeTicketsSold',
  managedInventory: 'cumulativeManagedInventory',
  revenue: 'cumulativeRevenue',
  cost: 'cumulativeCost',
  take: 'cumulativeTake',
  takeAttr: 'cumulativeTakeAttr',
  liftOfSold: 'cumulativeLiftOfSold',
  totalFaceValue: 'cumulativeIngestedFaceValue',
  soldFaceValue: 'cumulativeSoldFaceValue',
};

const PREVIEW_SALES_METRICS: Record<PreviewTrendMetric, CumulativeMmt> = {
  ticketsSold: 'cumulativeTicketsSold',
  soldFaceValue: 'cumulativeSoldFaceValue',
};

const accumulateSalesMetric = (
  acc: SalesSummaryMeasurement[],
  mmt: SalesSummaryMeasurement,
  metric: TrendMetric | PreviewTrendMetric,
  isPreview = false,
): number => {
  const metrics = isPreview ? PREVIEW_SALES_METRICS : SALES_METRICS;

  return acc[acc.length - 1][metrics[metric as keyof typeof metrics]] + mmt[metric];
};

const salesMetrics = Object.keys(SALES_METRICS) as (keyof typeof SALES_METRICS)[];
const previewSalesMetrics = Object.keys(PREVIEW_SALES_METRICS) as
  (keyof typeof PREVIEW_SALES_METRICS)[];

const timeZoneRegex = new RegExp('(Z|[-+][0-9]{2}:[0-9]{2})$');
const stripTimeZone = (v: string): string => v.replace(timeZoneRegex, '');

const formatTimeSeriesRollup = (timeSeriesRollup: Rollup[]): SalesSummaryMeasurement[] => {
  const seriesFvTicketCt = timeSeriesRollup.map(({ aggregate: a }) => a.ingestedFvTicketCt)
    .reduce((a, b) => a + b, 0);
  const seriesTicketCt = timeSeriesRollup.map(({ aggregate: a }) => a.ingestedTicketCt)
    .reduce((a, b) => a + b, 0);
  const hasSeriesFaceValue = seriesFvTicketCt > 0
    && seriesFvTicketCt === seriesTicketCt;

  const formattedRollup = timeSeriesRollup.map(({ aggregate, group }) => ({
    transactionDate: group?.time ? new Date(stripTimeZone(group.time)) : undefined,
    logHoursToEvent: group?.logHoursToEvent,
    ticketsSold: aggregate.saleTicketCt,
    managedInventory: aggregate.ingestedTicketCt,
    totalSales: aggregate.saleCt,
    revenue: aggregate.saleRevenue,
    cost: aggregate.ingestedCost,
    take: aggregate.overallGrossProfitIncr,
    takeAttr: aggregate.overallGrossProfitAttr,
    liftOfSold: hasSeriesFaceValue ? (aggregate.saleRevenue - aggregate.saleFaceValue) : undefined,
    totalFaceValue: hasSeriesFaceValue ? aggregate.ingestedFaceValue : undefined,
    soldFaceValue: hasSeriesFaceValue ? aggregate.saleFaceValue : undefined,
  }));

  return formattedRollup;
};

const formatTimeSeriesRollupPreview = (
  timeSeriesRollupPreview: RollupPreview[],
): SalesSummaryMeasurement[] => {
  const formattedRollupPreview = timeSeriesRollupPreview.map(({ group, aggregate }) => ({
    transactionDate: group?.time ? new Date(stripTimeZone(group.time)) : undefined,
    logHoursToEvent: group?.logHoursToEvent,
    ticketsSold: aggregate.saleTicketCt.target,
    soldFaceValue: aggregate?.saleFaceValue.target,
  }));

  return formattedRollupPreview;
};

const createCumulativeSalesPreviewTrends = (
  formattedTimeSeriesRollupPreview: SalesSummaryMeasurement[],
  lastActualMeasurement: EventTimeSeriesSalesSummary,
): SalesSummaryMeasurement[] => {
  const [initial, ...tail] = formattedTimeSeriesRollupPreview;
  const initialCumulativeValues = {
    ...initial,
    cumulativeTicketsSold:
      (initial?.ticketsSold ?? 0) + lastActualMeasurement.cumulativeTicketsSold,
    cumulativeSoldFaceValue:
      (initial?.soldFaceValue ?? 0) + lastActualMeasurement.cumulativeSoldFaceValue,
  };

  // creates connection point between actual / preview trends
  const startingPoint = {
    ...lastActualMeasurement,
    isPreview: true,
  };

  const timeValueType: 'transactionDate' | 'logHoursToEvent' = lastActualMeasurement.logHoursToEvent ? 'logHoursToEvent' : 'transactionDate';

  const cumulativeSalesPreviewSummary = tail.reduce(
    (acc, mmt) => {
      const newMmt: SalesSummaryMeasurement = { ...mmt };

      previewSalesMetrics.forEach((metric) => {
        const cumulativeMmt = accumulateSalesMetric(acc, mmt, metric);
        const mmtKey = PREVIEW_SALES_METRICS[metric];

        newMmt[mmtKey] = cumulativeMmt;
      });

      return [...acc, newMmt];
    },
    [initialCumulativeValues],
  )
    // exclude any preview measurement at the ~same time as last actual measurement
    .filter((d) => (
      !(typeof d[timeValueType] !== 'undefined'
        && Number(d[timeValueType]).toLocaleString() === (
          Number(lastActualMeasurement[timeValueType]).toLocaleString())
      )
    ));

  // include preview connection only if tickets left to sell
  return lastActualMeasurement.cumulativeTicketsUnsold === 0
    ? cumulativeSalesPreviewSummary
    : [startingPoint, ...cumulativeSalesPreviewSummary];
};

const createSalesPreviewTrendData = (
  timeSeriesRollupPreview: RollupPreview[],
  lastActualMeasurement: EventTimeSeriesSalesSummary,
): SalesSummaryMeasurement[] => {
  const formattedTimeSeriesRollupPreview = formatTimeSeriesRollupPreview(timeSeriesRollupPreview);

  return createCumulativeSalesPreviewTrends(
    formattedTimeSeriesRollupPreview,
    lastActualMeasurement,
  );
};

const createCumulativeSalesTrends = (
  formattedTimeSeriesRollup: SalesSummaryMeasurement[],
  startingInventory: number,
): SalesSummaryMeasurement[] => {
  const [initial, ...tail] = formattedTimeSeriesRollup;
  const initialCumulativeValues = {
    ...initial,
    cumulativeTicketsSold: initial?.ticketsSold,
    cumulativeManagedInventory: initial?.managedInventory + startingInventory,
    cumulativeRevenue: initial?.revenue,
    cumulativeCost: initial?.cost,
    cumulativeTake: initial?.take,
    cumulativeTakeAttr: initial?.takeAttr,
    cumulativeLiftOfSold: initial?.liftOfSold,
    cumulativeIngestedFaceValue: initial?.totalFaceValue,
    cumulativeSoldFaceValue: initial?.soldFaceValue,
  };

  const cumulativeSalesSummary = tail.reduce(
    (acc, mmt) => {
      const newMmt: SalesSummaryMeasurement = { ...mmt };

      salesMetrics.forEach((metric) => {
        const cumulativeMmt = accumulateSalesMetric(acc, mmt, metric);
        const mmtKey = SALES_METRICS[metric];

        newMmt[mmtKey] = cumulativeMmt;
      });

      return [...acc, newMmt];
    },
    [initialCumulativeValues],
  );

  return cumulativeSalesSummary;
};

const createSalesTrendData = (
  timeSeriesRollup: Rollup[],
  isRelativeTime = false,
  timeInterval = 1,
  startingInventory = 0,
): SalesSummaryMeasurement[] => {
  const formattedTimeSeriesRollup = formatTimeSeriesRollup(timeSeriesRollup);
  const firstMeasurement = formattedTimeSeriesRollup?.[0];

  const startsGreaterThanZero = firstMeasurement?.ticketsSold > 0;
  const zeroValues: SalesSummaryMeasurement = {
    ticketsSold: 0,
    cumulativeManagedInventory: 0,
    cumulativeSoldFaceValue: 0,
    cumulativeIngestedFaceValue: 0,
    cumulativeCost: 0,
    cumulativeTicketsSold: 0,
    cumulativeRevenue: 0,
    cumulativeTake: 0,
    logHoursToEvent: isRelativeTime ? firstMeasurement?.logHoursToEvent + timeInterval : undefined,
    transactionDate: (
      isRelativeTime
        ? undefined
        : (DateTime.fromJSDate(firstMeasurement?.transactionDate))
          .minus({ days: timeInterval })
          .toJSDate()
    ),
  };

  const trend = createCumulativeSalesTrends(formattedTimeSeriesRollup, startingInventory);
  const startFromZeroTrend = startsGreaterThanZero
    ? [zeroValues, ...trend]
    : trend;

  return (
    typeof startingInventory === 'undefined'
      ? []
      : startFromZeroTrend
  );
};

const getT0DateString = (rollup: Rollup[]): string | undefined => {
  if (rollup?.[0]) {
    const timeZonelessDateString = stripTimeZone(rollup?.[0].group.time);

    return DateTime.fromISO(timeZonelessDateString).toSQLDate();
  }

  return undefined;
};

const checkIsCurrency = (measure: CumulativeMmt): boolean => (
  ![SALES_METRICS.ticketsSold].includes(measure)
);

export {
  SALES_METRICS, getT0DateString, checkIsCurrency, createSalesPreviewTrendData,
};
export default createSalesTrendData;
