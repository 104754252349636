import { DateTime } from 'luxon';
import { PriceConstraintPolicy } from './Stakeholder';

interface Deal {
  id: number;
  stakeholderId: number;
  stakeholderName: string;
  name: string;
  displayName: string;
  description?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  dealTerms?: DealTerm[];
  createdAt: DateTime;
  updatedAt: DateTime;
}

interface PostDealRequest {
  stakeholderId?: number;
  name?: string;
  displayName?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
}

interface GetDealsResponse {
  deals: Deal[];
}

enum DealTermType {
  CONSIGNMENT = 'consignment',
  DIRECT_BUY = 'direct_buy',
  REVENUE_SHARE = 'revenue_share',
  MANAGEMENT_FEE = 'management_fee',
}

enum DealTermGranularity {
  AGGREGATE = 'aggregate',
  PER_TICKET = 'per_ticket',
}

enum DealTermRevenueShareBasis {
  COST_PER_TICKET = 'cost_per_ticket',
  FACE_VALUE_PER_TICKET = 'face_value_per_ticket',
}

interface DealTermRevenueShare {
  threshold: number;
  simRevenueShare: number;
}

interface DealTermProfits {
  id: number;
  ingestedTicketCt: number;
  ingestedCost: number;
  saleTicketCt: number;
  saleRevenue: number;
  saleCost: number;
  saleCommission: number;
  saleNetRevenue: number;
  saleAccountingProfit: number;
  overallGrossProfit: number;
  saleRevenueShareFracIncr: number;
  createdAt: DateTime;
  updatedAt: DateTime;
}

interface DealTerm {
  id: number;
  stakeholderId: number;
  dealId: number;
  name: string | null;
  dealName: string;
  description: string | null;
  dealTermType: DealTermType;
  granularity: DealTermGranularity;
  revenueShareBasis: DealTermRevenueShareBasis;
  revenueShares: DealTermRevenueShare[];
  priority: number | null;
  priceConstraintPolicy: PriceConstraintPolicy | null;
  profits: DealTermProfits;
  createdAt: DateTime;
  updatedAt: DateTime;
  expiredAt?: DateTime;
}

interface PostDealTermBody {
  stakeholderId?: number;
  name?: string;
  description?: string;
  priority?: number;
  clearPriority?: boolean;
  priceConstraintPolicy?: PriceConstraintPolicy;
  clearPriceConstraintPolicy?: boolean;
  dealTermType?: DealTermType;
  granularity?: DealTermGranularity;
  revenueShareBasis?: DealTermRevenueShareBasis;
  revenueShares?: DealTermRevenueShare[];
}

export {
  Deal,
  PostDealRequest,
  GetDealsResponse,
  PostDealTermBody,
  DealTerm,
  DealTermType,
  DealTermGranularity,
  DealTermRevenueShare,
  DealTermRevenueShareBasis,
};
