interface Projected {
  selldown: number;
  ticketsSelldown: number;
  ticketsNaive: number;
  ticketsTarget: number;
  ticketsAdjusted: number;
}

interface Actual {
  sales: Sales;
  autobroker?: LogData;
  competitor?: LogData;
}

interface Sales {
  transactionCt: number;
  ticketsUnsoldCt: number;
  ticketsSoldCt: number;
  ticketsSoldGtv: number;
}

interface LogData {
  listingCt: number;
  normalizedListingCt: number;
  logPriceRatio: number;
  logPriceRatioMin: number;
  logPriceRatio5Pct: number;
  logPriceRatio10Pct: number;
  logPriceRatio25Pct: number;
  logPriceRatio50Pct: number;
  logPriceRatio75Pct: number;
  logPriceRatio90Pct: number;
  logPriceRatio95Pct: number;
  logPriceRatioMax: number;
  logPriceRatioMu: number;
  logPriceRatioSd?: number;
  logPriceRatioSk?: number;
  logPriceRatioKt?: number;
  logExpectedValueMu: number;
  logExpectedValueSd?: number;
  logExpectedValueSk?: number;
  logExpectedValueKt?: number;
  logExpectedValueLogPriceRatio50Pct?: number;
}

interface Model {
  autobrokerInventoryCt: number;
  competitorInventoryCt: number;
  isUnconstrained: boolean;
  localDemand: number;
  localElasticity: number;
  localMeanTicketsPerTransaction: number;
  logCompetitivePriceScale: number;
  listingsRemaining: number;
  ticketsRemaining: number;
  logPriceRatioConstrained?: number;
  logPriceRatioTarget: number;
  logPriceRatioUnconstrained: number;
  logPriceRatio: number;
  parameters: Parameters;
}

interface Parameters {
  futureDemand: number;
  logPivotPriceRatio: number;
  elasticityPower: number;
  competitiveElasticity: number;
  monopolyFactor: number;
  logAdvantageFactor: number;
}

interface ApiSnapshot {
  snapshotAt: string;
  daysToEvent: number;
  logHoursToEvent: number;
  projected: Projected;
  actual: Actual;
  model?: Model;
}

interface ApiSnapshotResponse {
  autobrokerEventId: number;
  snapshots: Array<ApiSnapshot>;
}

interface GraphPlot {
  x: number;
  y: number;
  y0?: number;
  quantity?: number;
  inventorySourceType?: string;
  sellerId?: number;
  allInPrice?: number;
  displayPrice?: number;
  label?: string | number;
  id?: string;
  logPriceRatioAdj?: number;
  row?: string;
  section?: string;
  marketplace?: string;
}

type GraphArray = GraphPlot[];

enum Graphlabels {
  actualABLogPriceRatio = 'SIM',
  actualABPercentilesMedium = 'SIM',
  actualABPercentilesNarrow = 'SIM',
  actualABPercentilesWide = 'SIM',
  actualCompetitorLogPriceRatio = 'Competitor',
  actualCompetitorPercentilesMedium = 'Competitor',
  actualCompetitorPercentilesNarrow = 'Competitor',
  actualCompetitorPercentilesWide = 'Competitor',
  actualUnsoldTickets = 'Unsold',
  modelLogPriceRatio = 'Model',
  targetLogPriceRatio = 'Target',
  ticketsAdjusted = 'Adjusted',
  ticketsSelldown = 'Selldown',
  ticketsRemaining = 'Remaining',
  ticketsNaive = 'Naive',
  ticketsTarget = 'Target',
  ticketsTargetOverlay = 'Target',
  actualABListingCount = 'SIM',
  actualABNormalizedListingCount = 'SIM Normalized',
  actualCompetitorListingCount = 'Competitor',
  actualCompetitorNormalizedListingCount = 'Competitor Normalized',
  autobroker = 'SIM',
  competitor = 'Competitor',
  model = 'Model',
}

interface LongData extends GraphPlot {
  group: Graphlabels;
}

enum HeaderLabels {
  transactionCount = 'Transactions',
  ticketsUnsoldCount = 'Tickets Unsold',
  ticketsSoldCount = 'Tickets Sold',
  ticketsSoldGtv = 'GTV',
}

interface LegendEntry {
  name: string;
  symbol: { fill: string };
}

interface SelldownGraphData {
  actualUnsoldTickets?: GraphArray;
  ticketsAdjusted?: GraphArray;
  ticketsSelldown?: GraphArray;
  ticketsNaive?: GraphArray;
  ticketsRemaining?: GraphArray;
  ticketsTarget?: GraphArray;
  ticketsTargetOverlay?: GraphArray;
}

interface GraphDomain {
  x: any;
  y: any;
}

interface CurrentStateData {
  abListingCount: number;
  abLogPriceRatio: number;
  abNormalizedListingCount: number;
  competitorListingCount: number;
  competitorLogPriceRatio: number;
  competitorNormalizedListingCount: number;
  daysToEvent: number;
  futureDemand: number;
  isUnconstrained: boolean;
  localDemand: number;
  localElasticity: number;
  localMeanTicketsPerTransaction: number;
  logHoursToEvent: number;
  modelAbListingsRemaining: number;
  modelLogPriceRatio: number;
  modelLogPriceRatioTarget: number;
  projectedSelldown: number;
  ticketsSoldCount: number;
  ticketsSoldGtv: number;
  ticketsUnsoldCount: number;
  transactionCount: number;
  ticketsRemaining: number;
  listingsRemaining: number;
}

interface SelldownGraphInfo {
  graphData: SelldownGraphData;
  graphDataDaysX: SelldownGraphData;
  currentStateData?: CurrentStateData;
  graphDomain: GraphDomain;
  graphDomainHistorical?: GraphDomain;
}

interface PriceRatioGraphData {
  actualABPercentilesWide: GraphArray;
  actualABPercentilesMedium: GraphArray;
  actualABPercentilesNarrow: GraphArray;
  actualABLogPriceRatio: GraphArray;
  actualCompetitorPercentilesWide: GraphArray;
  actualCompetitorPercentilesMedium: GraphArray;
  actualCompetitorPercentilesNarrow: GraphArray;
  actualCompetitorLogPriceRatio: GraphArray;
  modelLogPriceRatio: GraphArray;
  targetLogPriceRatio: GraphArray;
}

interface PriceRatioLongData extends LongData {
  y1?: number;
  measure: string;
}

interface PriceRatioGraphInfo {
  priceRatioGraphData: PriceRatioGraphData;
  priceRatioGraphDomain: GraphDomain;
}

interface ListingsGraphData {
  actualABListingCount: GraphArray;
  actualABNormalizedListingCount: GraphArray;
  actualCompetitorListingCount: GraphArray;
  actualCompetitorNormalizedListingCount: GraphArray;
}

interface ListingsGraphInfo {
  listingsGraphData: ListingsGraphData;
  listingsGraphDomain: GraphDomain;
}

interface SnapshotData {
  listingsGraphInfo: ListingsGraphInfo;
  priceRatioGraphInfo: PriceRatioGraphInfo;
  selldownGraphInfo: SelldownGraphInfo;
}

enum GraphDataTypes {
  PriceRatioGraphData,
  SelldownGraphData,
}

export default ApiSnapshot;
export {
  Actual,
  ApiSnapshotResponse,
  GraphArray,
  GraphDataTypes,
  GraphDomain,
  Graphlabels,
  GraphPlot,
  HeaderLabels,
  LegendEntry,
  ListingsGraphData,
  ListingsGraphInfo,
  LogData,
  Model,
  Projected,
  PriceRatioGraphData,
  PriceRatioGraphInfo,
  Sales,
  SelldownGraphData,
  CurrentStateData,
  SelldownGraphInfo,
  SnapshotData,
  LongData,
  PriceRatioLongData,
};
