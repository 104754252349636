import styled from 'styled-components';
import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import {
  SectionWrapper,
  ButtonsWrapper,
  Header,
  InnerWrapper,
  Label,
  Input,
  TextArea,
  Content,
  modalMenuProps,
} from '#/shared/modalComponents';
import Button from '#/shared/Button';
import formatApiError from '#/api/utils/formatApiError';
import { type GroupOption } from '#/pages/useFetchGroups';
import Notification from '#/shared/Notification';
import Group, { GroupState } from '#/types/Group';
import postGroup from '#/api/postGroup';
import deleteGroup from '#/api/deleteGroup';
import modalStyles from '#/shared/modalStyles';
import useSetupModal from '#/pages/useSetupModal';
import Trashcan from './Trashcan';

const CREATE = 'Create Event Group';
const UPDATE = 'Update Event Group';
const NAME = 'Name';
const DISPLAY_NAME = 'Display Name (Optional)';
const DESCRIPTION = 'Description (Optional)';
const EVENT_IDS = 'Autobroker Event IDs (Separated by commas)';

const SUBMIT = 'Submit';
const CANCEL = 'Cancel';
const DELETE = 'Delete';

const DeleteButtonContents = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

interface KeyboardInputEvent extends React.KeyboardEvent<HTMLInputElement> {
  target: HTMLInputElement;
}

interface KeyboardTextAreaEvent extends React.KeyboardEvent<HTMLTextAreaElement> {
  target: HTMLTextAreaElement;
}

interface GroupModalProps {
  isUpdate: boolean;
  closeModal: () => void;
  groupLabels: GroupOption[];
  groups: Group[];
  autobrokerEventIds?: number[];
}

const GroupModal: React.FC<GroupModalProps> = ({
  isUpdate,
  closeModal,
  groupLabels,
  groups,
  autobrokerEventIds,
}) => {
  const [initial, setInitial] = useState<Group | null>(null);
  const [groupState, setGroupState] = useState<GroupState>(
    { autobrokerEventIds: autobrokerEventIds || [] as number[] },
  );

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onChangeGroup = useCallback((event: GroupOption) => {
    const name = event.value;
    const group = groups.find((g) => g.name === name);
    const ids = autobrokerEventIds || group?.autobrokerEventIds || [];

    setInitial(group);

    setGroupState((g: GroupState): GroupState => ({
      ...g,
      name,
      autobrokerEventIds: ids,
    }));
  }, [setGroupState, groups, autobrokerEventIds]);

  const onChangeName = useCallback((event: KeyboardInputEvent) => {
    const name = event.target?.value || null;

    setGroupState((g: GroupState): GroupState => ({ ...g, name }));
  }, [setGroupState]);

  const onChangeDisplayName = useCallback((event: KeyboardInputEvent) => {
    const displayName = event.target?.value || null;

    setGroupState((g: GroupState): GroupState => ({
      ...g, displayName,
    }));
  }, [setGroupState]);

  const onChangeDescription = useCallback((event: KeyboardTextAreaEvent) => {
    const description = event.target?.value || null;

    setGroupState((g: GroupState): GroupState => ({
      ...g, description,
    }));
  }, [setGroupState]);

  const convertStringToNumberList = (myString: string): number[] => {
    return myString ? myString.split(',').map((n) => Number(n) || null).filter((n) => n !== null) : [];
  };

  const onChangeEventIds = useCallback((event: KeyboardInputEvent) => {
    const ids = convertStringToNumberList(event.target?.value || null);

    setGroupState((g: GroupState): GroupState => ({
      ...g, autobrokerEventIds: ids,
    }));
  }, [setGroupState]);

  const cancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const submitPostGroup = useCallback(async () => {
    try {
      setError('');
      const newGroup = await postGroup(groupState);

      if (newGroup.name) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, groupState]);

  const submitDeleteGroup = useCallback(async () => {
    try {
      setError('');
      const status = await deleteGroup(groupState.name);

      if (status === 200) {
        setSuccess(true);
        setTimeout(closeModal, 1000);
      }
    } catch (err) {
      const errorString = formatApiError(err);

      setError(errorString);
    }
  }, [closeModal, groupState]);

  useSetupModal(cancel);

  return (
    <Modal
      isOpen
      onRequestClose={cancel}
      style={modalStyles}
    >
      <Header>{isUpdate ? UPDATE : CREATE}</Header>
      <Notification error={error} success={success} />
      <SectionWrapper>
        <Content>
          {
            isUpdate
              ? (
                <InnerWrapper>
                  <Label>{NAME}</Label>
                  <Select
                    onChange={onChangeGroup}
                    options={groupLabels}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...modalMenuProps}
                  />
                </InnerWrapper>
              )
              : (
                <InnerWrapper>
                  <Label>{NAME}</Label>
                  <Input onKeyUp={onChangeName} type="string" />
                </InnerWrapper>
              )
          }
          <InnerWrapper>
            <Label>{DISPLAY_NAME}</Label>
            <Input
              defaultValue={initial?.displayName}
              onKeyUp={onChangeDisplayName}
              type="string"
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{DESCRIPTION}</Label>
            <TextArea
              defaultValue={initial?.description}
              onKeyUp={onChangeDescription}
            />
          </InnerWrapper>
          <InnerWrapper>
            <Label>{EVENT_IDS}</Label>
            <Input
              defaultValue={(autobrokerEventIds || initial?.autobrokerEventIds)?.join(',')}
              onKeyUp={onChangeEventIds}
              type="string"
            />
          </InnerWrapper>
        </Content>
        <ButtonsWrapper>
          <Button disabled={!groupState.name} onClick={submitPostGroup}>{SUBMIT}</Button>
          <Button onClick={cancel}>{CANCEL}</Button>
          {(isUpdate) ? (
            <Button disabled={!groupState.name} onClick={submitDeleteGroup}>
              <DeleteButtonContents>
                <Trashcan />
                {' '}
                {DELETE}
              </DeleteButtonContents>
            </Button>
          ) : null}
        </ButtonsWrapper>
      </SectionWrapper>
    </Modal>
  );
};

export default GroupModal;
