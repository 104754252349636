import { invSymLog } from '#/pages/ViewPage/components/Pricing/utils/dataFormatting';
import createSalesTrendData, { createSalesPreviewTrendData } from '#/shared/reporting/accumulationUtils';
import type {
  RollupPreview, Rollup, RollupGroups, SalesSummaryMeasurement,
} from '#/types/ReportingRollup';
import { uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { DEFAULT_LOG_HOURS_TO_EVENT_INTERVAL } from '../constants';

interface RollupEvent {
  eventType?: string;
  autobrokerEventId?: number;
  eventTitle?: string;
  eventVenue?: string;
  eventStartsAtLocal?: DateTime;
  eventStartsAt?: DateTime;
  mapConfigId?: number;
}

interface EventTimeSeriesSalesSummary extends SalesSummaryMeasurement {
  cumulativeTicketsUnsold: number;
  cumulativeTicketsUnsoldPercent?: number;
  cumulativeLiftOfSoldPercent?: number;
  cumulativeAverageTicketPrice?: number;
  autobrokerEvent: RollupGroups;
  timeValue: number | Date;
  isPreview: boolean;
}

interface EventSalesTrends {
  autobrokerEvents: RollupEvent[];
  salesTrendsByEvent: EventTimeSeriesSalesSummary[];
  salesPreviewTrendsByEvent: EventTimeSeriesSalesSummary[];
}

interface GetSalesTrendsByEventProps {
  eventLevelRollup: Rollup[],
  eventLevelRollupPreview: RollupPreview[],
  reversedX?: boolean;
  timeToEventHoursCutoff?: number;
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const isDefined = (v: any): boolean => (!Number.isNaN(v) && typeof v !== 'undefined' && v !== null);
const calcFraction = (
  numerator: number | undefined | null,
  denominator: number | undefined | null,
): number | undefined => (
  (!isDefined(numerator) || !isDefined(denominator) || (denominator === 0))
    ? undefined
    : (numerator / denominator)
);

/**
* @description takes a rollup and groups by event to get multi-event,
* cumulative time series sales metrics
* @see {`useFetchReportingRollup`}
*/
const getSalesTrendsByEvent = ({
  eventLevelRollup,
  eventLevelRollupPreview,
  reversedX = false,
  timeToEventHoursCutoff = -8,
}: GetSalesTrendsByEventProps): EventSalesTrends => {
  const autobrokerEvents = uniqBy(eventLevelRollup?.map((r) => {
    const { time, logHoursToEvent, ...rest } = r.group;

    return rest;
  }), 'autobrokerEventId');

  const salesTrendsByEvent = eventLevelRollup?.length > 0
    ? (
      autobrokerEvents?.flatMap((autobrokerEvent) => {
        const thisEventsRollup = eventLevelRollup.filter(({ group }) => (
          group.autobrokerEventId === Number(autobrokerEvent.autobrokerEventId)
          // exclude measurements farther past event start time
          && (reversedX ? invSymLog(group.logHoursToEvent) >= timeToEventHoursCutoff : true)
        ));

        const totalManagedInventory = (thisEventsRollup.map((r) => r.aggregate.ingestedTicketCt)
          .reduce((a, b) => a + b, 0));

        const salesTrendData = createSalesTrendData(
          reversedX ? thisEventsRollup.reverse() : thisEventsRollup,
          reversedX,
          reversedX ? DEFAULT_LOG_HOURS_TO_EVENT_INTERVAL : 1,
        )
          .map((r) => {
            const timeValue = r.transactionDate ?? r.logHoursToEvent;

            const cumulativeTicketsUnsold = totalManagedInventory - r.cumulativeTicketsSold;
            const cumulativeTicketsUnsoldPercent = calcFraction(
              cumulativeTicketsUnsold, totalManagedInventory,
            );
            const cumulativeLiftOfSoldPercent = calcFraction(
              r.cumulativeLiftOfSold, r.cumulativeSoldFaceValue,
            );

            // TODO: replace with a smoothed ticket price (e.g. EMA)
            const cumulativeAverageTicketPrice = calcFraction(
              r.cumulativeRevenue, r.cumulativeTicketsSold,
            );

            return {
              ...r,
              totalManagedInventory,
              cumulativeTicketsUnsold,
              cumulativeTicketsUnsoldPercent,
              cumulativeLiftOfSoldPercent,
              cumulativeAverageTicketPrice,
              autobrokerEvent,
              timeValue,
              isPreview: false,
            };
          });

        return salesTrendData;
      })
    )
    : [];

  const salesPreviewTrendsByEvent = eventLevelRollupPreview?.length > 0
    ? (
      autobrokerEvents?.flatMap((autobrokerEvent) => {
        const thisEventsRollupPreview = eventLevelRollupPreview.filter(({ group }) => (
          group.autobrokerEventId === Number(autobrokerEvent.autobrokerEventId)
        ));
        const thisEventsSalesTrend = salesTrendsByEvent.filter(
          ({ autobrokerEvent: trendEvent }) => (
            Number(autobrokerEvent.autobrokerEventId) === trendEvent.autobrokerEventId),
        );
        const lastEventRollupMeasurement = thisEventsSalesTrend[thisEventsSalesTrend?.length - 1];

        return createSalesPreviewTrendData(
          thisEventsRollupPreview,
          lastEventRollupMeasurement,
        ).map((r) => {
          const timeValue = r.transactionDate ?? r.logHoursToEvent;

          const cumulativeTicketsUnsold = (
            lastEventRollupMeasurement.totalManagedInventory - r.cumulativeTicketsSold
          );

          const cumulativeTicketsUnsoldPercent = (
            (!isDefined(lastEventRollupMeasurement?.totalManagedInventory)
              || lastEventRollupMeasurement?.totalManagedInventory === 0)
              ? undefined
              : cumulativeTicketsUnsold / lastEventRollupMeasurement.totalManagedInventory
          );

          return {
            ...r,
            autobrokerEvent,
            timeValue,
            cumulativeTicketsUnsold,
            cumulativeTicketsUnsoldPercent,
            isPreview: true,
          };
        })
        // exclude preview measurements before actual measurements
          .filter((d) => (
            reversedX
              ? d.timeValue <= lastEventRollupMeasurement.timeValue
              : d.timeValue >= lastEventRollupMeasurement.timeValue
          ));
      })
    )
    : [];

  return {
    salesTrendsByEvent,
    salesPreviewTrendsByEvent,
    autobrokerEvents,
  };
};

export default getSalesTrendsByEvent;
export { isDefined, calcFraction };
export type {
  EventTimeSeriesSalesSummary,
  EventSalesTrends,
  GetSalesTrendsByEventProps,
  RollupEvent,
};
