/* eslint-disable import/max-dependencies */
import React from 'react';
import styled from 'styled-components';
import { EventErrorType } from '#/types/Errors';
import Event, { DynamicPricingModel, PricingBasis, StrategyType } from '#/types/Event';
import Selected from '#images/Selected.svg';
import Unselected from '#images/Unselected.svg';
import Plus from '#images/Plus.svg';
import Minus from '#images/Minus.svg';
import Mapped from '#images/Mapped.svg';
import Parking from '#images/Parking.svg';
import GA from '#images/GA.svg';
import QA from '#images/QA.svg';
import KeepActive from '#images/KeepActive.svg';
import ExploreBoat from '#images/ExploreBoat.svg';
import ExploreSubway from '#images/ExploreSubway.svg';
import Peakpass from '#images/Peakpass.svg';
import Oneticket from '#images/Oneticket.svg';
import Ticketvault from '#images/Ticketvault.svg';
import Consumer from '#images/ConsumerUploader.svg';
import PriceConstraint from '#images/PriceConstraint.svg';
import MultiValue from '#images/MultiValue.svg';
import DisplayPrice from '#images/DisplayPrice.svg';
import PendingModels from '#images/PendingModels.svg';

const MODEL_ERRORS: EventErrorType[] = [
  EventErrorType.ONSALE_MODEL_ERROR,
  EventErrorType.SELLDOWN_MODEL_ERROR,
];

interface EventIconsProps {
  event: Event;
}

const EventIcons: React.FC<EventIconsProps> = ({ event }) => {
  const hasOnsalePricingModel = event.models.onsalePricingModel !== null;
  const hasSelldownProjectionModel = event.models.selldownProjectionModel !== null;

  const hasModelError = (
    event.eventErrors.filter((e) => MODEL_ERRORS.includes(e.errorType)).length > 0
  );
  const pendingModels = [
    hasOnsalePricingModel ? null : 'onsale pricing model',
    hasSelldownProjectionModel ? null : 'selldown projection model',
  ].filter((m) => m !== null);
  const hasPendingModels = (pendingModels.length > 0) && !hasModelError;

  let pricingModeImage = null;

  if (event.config.pricingMode === 'mapped')
    pricingModeImage = Mapped;
  else if (event.config.pricingMode === 'parking')
    pricingModeImage = Parking;
  else
    pricingModeImage = GA;

  const sources = [...new Set(
    event.sourceEvents
      .filter((se) => !se.expiredAt)
      .map((se) => se.sourceName),
  ),
  ].sort() as string[];

  return (
    <>
      <img
        alt="is-active"
        src={event.config.isActive ? Selected : Unselected}
        title={event.config.isActive ? 'Active' : 'Inactive'}
      />
      {
        sources.map((source: string) => (
          <OffsetImage
            alt={source}
            height="25"
            key={source}
            src={sourceImage(source)}
            title={`has ${source} integration`}
          />
        ))
      }
      <OffsetImage
        alt="pricing-mode"
        height="25"
        src={pricingModeImage}
        title={'pricing mode: ' + event.config.pricingMode}
      />
      {
        event.priceExploration.strategyType !== StrategyType.NONE && (
          <OffsetImage
            alt="price-exploration"
            height="25"
            src={
              event.priceExploration.strategyType === StrategyType.CONSTANT
              || event.priceExploration.strategyType === StrategyType.INCREASING
              || event.priceExploration.strategyType === StrategyType.DECREASING
                ? ExploreSubway : ExploreBoat
            }
            title={
              `price exploration: ${event.priceExploration.strategyType} `
              + `${event.priceExploration.lowerPercentile}-${event.priceExploration.upperPercentile} `
              + `${event.priceExploration.frequency}x`
            }
          />
        )
      }
      {
        event.config.keepSupervisorActive && (
          <OffsetImage
            alt="keep-supervisor-active"
            height="25"
            src={KeepActive}
            title="keep the supervisor active"
          />
        )
      }
      { event.config.sectionSubset.type !== 'none' && (
        <OffsetImage
          alt="section-subset"
          height="25"
          src={event.config.sectionSubset.type === 'positive' ? Plus : Minus}
          title={event.config.sectionSubset.type + ' section subset:\n' + event.config.sectionSubset.sections.join(', ')}
        />
      )}
      { event.sinkEvents.some((se) => se.qaMode) && (
        <OffsetImage
          alt="qa-mode"
          height="25"
          src={QA}
          title="qa mode"
        />
      )}
      { event.hasPriceConstraints && (
        <OffsetImage
          alt="has price constraints"
          height="25"
          src={PriceConstraint}
          title="price constraints"
        />
      )}
      { event.config.pricingBasis === PricingBasis.DISPLAY_PRICE && (
        <OffsetImage
          alt="display price basis"
          height="25"
          src={DisplayPrice}
          title="using the display price as the basis for pricing"
        />
      )}
      { event.config.dynamicPricingModel === DynamicPricingModel.multivalue && (
        <OffsetImage
          alt="multivalue dynamic pricing model"
          height="25"
          src={MultiValue}
          title="using the multivalue dynamic pricing model"
        />
      )}
      { hasPendingModels && (
        <OffsetImage
          alt="pending models"
          height="25"
          src={PendingModels}
          title={`pending: ${pendingModels.join(', ')}`}
        />
      )}
    </>
  );
};

const sourceImage = (source: string): string => {
  if (source === 'oneticket')
    return Oneticket;
  if (source === 'consumer')
    return Consumer;
  if (source === 'peakpass')
    return Peakpass;

  return Ticketvault;
};

const OffsetImage = styled.img`
  padding-left: 5px;
  padding-bottom: 3px;
`;

export default EventIcons;
