import { formatXTicks } from '#/pages/ViewPage/components/Pricing/utils/dataFormatting';
import { formatCurrency, formatGtv, formatPercentage } from '#/shared/formatNumberForDisplay';
import { startCase } from 'lodash';
import type { CumulativeMmt } from '#/types/ReportingRollup';

type CumulativeMetricKey = CumulativeMmt
| 'cumulativeTicketsUnsoldPercent'
| 'cumulativeAverageTicketPrice'
| 'cumulativeLiftOfSoldPercent'

const TAKE_KEY: CumulativeMetricKey = 'cumulativeTakeAttr';
const LIFT_KEY: CumulativeMetricKey = 'cumulativeLiftOfSoldPercent';
const UNSOLD_PERC_KEY: CumulativeMetricKey = 'cumulativeTicketsUnsoldPercent';
const ZERO_BASELINE_METRICS: CumulativeMetricKey[] = [
  TAKE_KEY, LIFT_KEY, UNSOLD_PERC_KEY,
];
const PREVIEWABLE_METRICS: CumulativeMetricKey[] = [UNSOLD_PERC_KEY, 'cumulativeTicketsSold'];

interface MetricOption {
  key: CumulativeMetricKey
  format?: ({ value, showDecimal }: { value: number, showDecimal?: boolean }) => string
  label?: string
}

const METRIC_OPTIONS: MetricOption[] = [
  {
    key: UNSOLD_PERC_KEY,
    format: ({ value }: { value: number }): string => formatPercentage(value),
    label: 'Tickets Unsold (%)',
  },
  { key: 'cumulativeTicketsSold', format: ({ value }: { value: number }): string => value.toLocaleString(undefined, { maximumFractionDigits: 1 }) },
  {
    key: 'cumulativeManagedInventory',
    format: ({ value }: { value: number }): string => (
      value.toLocaleString(undefined, { maximumFractionDigits: 1 })
    ),
    label: 'Tickets Ingested',
  },
  { key: 'cumulativeRevenue', format: ({ value }: { value: number }): string => formatGtv(value, false) },
  {
    key: LIFT_KEY,
    format: ({ value }: { value: number }): string => formatPercentage(value),
    label: 'Lift of Sold (%)',
  },
  {
    key: TAKE_KEY,
    format: ({ value }: { value: number }): string => formatGtv(value, false),
    label: 'Take (attr.)',
  },
  {
    key: 'cumulativeAverageTicketPrice',
    format: ({ value, showDecimal = true }: { value: number, showDecimal?: boolean }): string => (
      showDecimal
        ? formatCurrency(value)
        : formatGtv(value, false)
    ),
  },
].map((v) => ({
  label: startCase(v.key).split(' ').slice(1).join(' '),
  ...v,
})) as MetricOption[];

enum TimeMeasurement {
  CALENDAR = 'time',
  TIME_TO_EVENT = 'log_hours_to_event',
}

interface TimeOption {
  key: TimeMeasurement;
  label: string;
  format?: ({ value }: { value: number }) => string
}

const TIME_OPTIONS: TimeOption[] = [
  {
    key: TimeMeasurement.TIME_TO_EVENT,
    label: 'To event',
    format: formatXTicks,
  },
  { key: TimeMeasurement.CALENDAR, label: 'Calendar' },
];

const METRIC_INPUT_LABEL = 'Sales Metric';
const TIME_INPUT_LABEL = 'Time measurement';

export {
  METRIC_OPTIONS,
  METRIC_INPUT_LABEL,
  TIME_OPTIONS,
  TIME_INPUT_LABEL,
  TimeMeasurement,
  TAKE_KEY,
  LIFT_KEY,
  ZERO_BASELINE_METRICS,
  PREVIEWABLE_METRICS,
  type CumulativeMetricKey,
  type MetricOption,
  type TimeOption,
};
