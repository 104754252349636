import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import getGroups from '#/api/getGroups';
import Group from '#/types/Group';
import formatApiError from '#/api/utils/formatApiError';
import { Option } from './useFetchStakeholders';

interface GroupOption extends Option<string> {
  hasEvents?: boolean;
}

interface FetchGroupsHook {
  groupLabels: GroupOption[];
  groups: Group[];
  groupError: string;
  groupLoading: boolean;
  fetchGroups: () => void;
  defaultGroup?: string;
  setDefaultGroup?: (value: string) => void;
}

const useFetchGroups = (): FetchGroupsHook => {
  const [groups, setGroups] = useState<Group []>([]);
  const [groupError, setGroupError] = useState<string>('');
  const [groupLoading, setGroupLoading] = useState<boolean>(false);
  const [defaultGroup, setDefaultGroup] = useState<string>(undefined);

  const fetchGroups = useCallback(async () => {
    try {
      setGroupLoading(true);
      const g = await getGroups();

      setGroups(g);
    } catch (err) {
      const errorString = formatApiError(err);

      setGroupError(errorString);
    }
    setGroupLoading(false);
  }, [setGroups, setGroupError]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const groupLabels = useMemo(() => {
    return groups
      .sort((a, b) => {
        if (a.isActive === b.isActive)
          return b.id - a.id;

        return a.isActive > b.isActive ? -1 : 1;
      })
      .map((group) => ({
        id: group.id,
        value: group.name,
        label: group.displayName,
        hasEvents: group.autobrokerEventIds?.length > 0,
      }));
  }, [groups]);

  useEffect(() => {
    if (!defaultGroup && groupLabels.length > 0)
      setDefaultGroup(groupLabels[0].value);
  }, [defaultGroup, groupLabels]);

  return {
    groupLabels,
    groups,
    groupError,
    groupLoading,
    fetchGroups,
    defaultGroup,
    setDefaultGroup,
  };
};

export default useFetchGroups;
export { FetchGroupsHook, GroupOption };
