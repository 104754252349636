import React from 'react';
import {
  ErrorStatusType, ErrorType, EventError, PostErrorStatus,
} from '#/types/Errors';
import Checklist, { ChecklistConfigItem } from './checklist';
import { MOPS_FORM_LINK } from '../utils';

const SCHEDULED_CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: "Event Status in Rufus = 'HIDDEN'",
  },
  {
    id: 1,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Event is Scheduled on Primary',
    parent_id: 0,
  },
  {
    id: 2,
    level: 3,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Mops Form Submitted',
    parent_id: 1,
    link: MOPS_FORM_LINK,
  },
  {
    id: 3,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Other Seatgeek Event is Active',
    parent_id: 0,
  },
  {
    id: 4,
    level: 3,
    status: ErrorStatusType.RESOLVED,
    notes: 'Seatgeek Event ID changed in Sapient',
    parent_id: 3,
  },
];

const CANCELLED_CONFIG: ChecklistConfigItem[] = [
  {
    id: 0,
    level: 1,
    status: ErrorStatusType.RESOLVED,
    notes: 'Event is Cancelled on Primary',
  },
  {
    id: 1,
    level: 1,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Event is NOT Cancelled on Primary',
  },
  {
    id: 2,
    level: 2,
    status: ErrorStatusType.INPROGRESS,
    notes: 'Mops Form Submitted',
    parent_id: 1,
    link: MOPS_FORM_LINK,
  },
];

interface ErrorInfoProps {
  error: EventError;
  setErrorStatus: (errorStatus: PostErrorStatus) => void;
}

const EventNotActiveChecklist: React.FC<ErrorInfoProps> = ({ error, setErrorStatus }) => {
  let config = null;

  if (error.errorReason.toLowerCase().includes('schedule_status = as_originally_scheduled')) {
    // Update errorStatus if error status is null and status == hidden
    if ((error.errorStatus === null || error.errorStatus.status === ErrorStatusType.TODO) && error.errorReason.toLowerCase().includes('status = hidden')) {
      setErrorStatus({
        status: ErrorStatusType.INPROGRESS,
        notes: "Event Status in Rufus = 'HIDDEN'",
        reference: {
          id: error.id,
          type: ErrorType.AUTOBROKER_EVENT_ERRORS,
        },
      });
    }
    config = SCHEDULED_CONFIG;
  }
  if (
    error.errorReason.toLowerCase().includes('schedule_status = cancelled')
    || error.errorReason.toLowerCase().includes('schedule_status = recon_inactive')
  )
    config = CANCELLED_CONFIG;

  if (config)
    return <Checklist config={config} error={error} setErrorStatus={setErrorStatus} />;
  return <></>;
};

export default EventNotActiveChecklist;
