import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '#/shared/Header';
import { User } from '#/types/User';
import camelcaseKeys from 'camelcase-keys';
import { parse } from 'query-string';
import RollupErrorComponent from '../clientReporting/DashboardPage/components/RollupErrorComponent';
import EventCollectionRollup from './EventCollectionRollup';
import { NUM_EVENTS_LIMIT } from './EventCollectionList';
import { EventCollectionProviders } from './contexts';
import { type FetchGroupsHook } from '#/pages/useFetchGroups';
import Loader from '#/shared/Loader';

interface Props {
  user: User;
  groupsHook: FetchGroupsHook;
}

interface SearchType {
  autobrokerEventIds?: string[]
  group?: string
  bypassCache?: boolean
}

const EventCollectionPage: React.FC<Props> = ({ user, groupsHook }) => {
  const { groups, defaultGroup, setDefaultGroup } = groupsHook;
  const { search: locationSearch } = useLocation();
  const { autobrokerEventIds, group, bypassCache } = camelcaseKeys(
    parse(locationSearch, { arrayFormat: 'comma' }),
  ) as SearchType;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (!group && !autobrokerEventIds && !!defaultGroup)
      navigate(`/view/collection?group=${defaultGroup}`, { replace: true });
    if (!!group && group !== defaultGroup && !!setDefaultGroup)
      setDefaultGroup(group);
  }, [group, autobrokerEventIds, defaultGroup, setDefaultGroup, navigate]);

  // handle the specific case of a single-group collection
  const selectedGroup = groups?.find((g) => g.name === group);
  const selectedGroupEventIds = selectedGroup?.autobrokerEventIds?.map((id) => String(id)) || [];
  // handle the specific case of a single-event collection
  const eventList = selectedGroupEventIds.concat(autobrokerEventIds ?? []);
  const eventListTruncated = useMemo(() => (
    eventList.slice(page * NUM_EVENTS_LIMIT, (page + 1) * NUM_EVENTS_LIMIT)
  ), [eventList, page]);

  const groupHasNoDefinedEvents = selectedGroup?.autobrokerEventIds === null;

  const isAllowed = !groupHasNoDefinedEvents && eventList.every((id) => Number(id));
  const isLoading = (!!group && !selectedGroup)
    || (eventListTruncated.length === 0 && !groupHasNoDefinedEvents);

  return (
    <>
      <Container>
        <Header
          toggleOpen={null}
          user={user}
        />
      </Container>
      <EventCollectionProviders>
        <EventCollectionWrapper>
          {!isLoading && isAllowed && (
            <EventCollectionRollup
              autobrokerEventIds={eventListTruncated}
              bypassCache={bypassCache}
              group={group}
              groupsHook={groupsHook}
              page={page}
              selectedGroup={selectedGroup}
              setPage={setPage}
              totalEventCt={eventList.length}
              user={user}
            />
          )}
          {!isLoading && !isAllowed && (
            <RollupErrorComponent />
          )}
          {isLoading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </EventCollectionWrapper>
      </EventCollectionProviders>
    </>
  );
};

const EventCollectionWrapper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  position: fixed;
  z-index: 2;
  background: ${({ theme }: { theme: Theme }): string => theme.color.background.info};
  color: ${({ theme }: { theme: Theme }): string => theme.color.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: padding 0.75s ease;
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default EventCollectionPage;
